import DocuSignLogo from '../../assets/Docusign.png'
import PlannerLogo from '../../assets/PlannerAppTile.svg'
import ProactisLogo from '../../assets/Proactis_logo-Icon.svg'
import { Application } from '../../Types/Application'

const isDEV = window.location.origin.toLowerCase().includes('dev')
const isUAT = window.location.origin.toLowerCase().includes('uat')
const isPreProd = window.location.origin.toLowerCase().includes('preprod')

const ProactisURL = 'https://proactis.uk365office.co.uk/'

const DocusignUrl =
  'https://bet365group.okta.com/home/docusign/0oa5duci3c8c3tyYp417/292'

let PlannerURL = 'https://planner365.bss.hillsidenewmedia.com/'

if (isDEV) {
  PlannerURL = 'https://planner365.bss.dev.hillsidenewmedia.com/'
} else if (isUAT) {
  PlannerURL = 'https://planner365.bss.uat.hillsidenewmedia.com/'
} else if (isPreProd) {
  PlannerURL = 'https://planner365.bss.preprod.hillsidenewmedia.com/'
}

export const applications: Application[] = [
  {
    id: 1,
    name: 'planner365',
    description: 'Application description...',
    url: PlannerURL,
    img: PlannerLogo,
    grp: 'App_Okta_Planner365',
    edgeRequired: false,
  },
  {
    id: 2,
    name: 'Docusign',
    description: 'Application description...',
    url: DocusignUrl,
    img: DocuSignLogo,
    grp: 'App_Okta_DocuSign',
    edgeRequired: false,
  },
  {
    id: 3,
    name: 'Proactis',
    description: 'Application description...',
    url: ProactisURL,
    img: ProactisLogo,
    grp: 'Tool_Proactis Portal Users',
    edgeRequired: true,
  },
]
